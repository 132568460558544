<template>
  <div>
    <v-row v-for="(item, index) in items" :key="index" dense>
      <v-col cols="4">
        <v-text-field
          v-model="item.title"
          type="text"
          label="Link title"
          outlined
          dense
        />
      </v-col>
      <v-col cols="7">
        <v-text-field
          v-model="item.url"
          type="text"
          label="Link URL"
          outlined
          dense
        />
      </v-col>
      <v-col cols="1">
        <v-btn
          icon
          small
          color="primary"
          class="mt-2"
          @click="deleteLink(index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-btn
          text
          rounded
          color="primary"
          class="px-3 ma-0"
          @click="addLink()"
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Add link
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PartnerWidgetLinks',
  props: {
    links: {
      type: Array,
      default: new Array(),
    },
  },
  data: () => ({
    items: new Array(),
  }),
  watch: {
    links: function (newValue, oldValue) {
      this.items = newValue;
    },
  },
  beforeMount() {
    this.items = this.links;
  },
  methods: {
    addLink() {
      this.items.push({
        title: null,
        url: null,
      });
    },
    deleteLink(index) {
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
  },
};
</script>
